
export const AppbarStyles = (theme: any) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexShrink: 0,
    borderRadius: "999px",
    backdropFilter: "blur(24px)",
    maxHeight: 40,
    border: "1px solid",
    borderColor: "divider",
    bgcolor: "hsla(220, 60%, 99%, 0.6)",
    boxShadow: "0 0 24px 12px hsla(210, 100%, 25%, 0.2)",
    ...theme.applyStyles("dark", {
      bgcolor: "hsla(220, 0%, 0%, 0.7)",
    })
  });