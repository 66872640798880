import { Stack } from "@mui/material";
import { FeatureCard } from "../../cards/FeatureCard";

export const FeaturesStack = (props: any) => {
  const items: any = props.items;
  const selectedItemIndex: number = props.selectedItemIndex;
  const clickCallback: (index: number) => void = props.clickCallback;

  return (
    <Stack
      direction="column"
      spacing={2}
      useFlexGap
      sx={{
        justifyContent: "center",
        alignItems: "flex-start",
        width: "100%",
      }}
    >
      {items.map(({ icon, title, description }: any, index: number) => (
        <FeatureCard
          icon={icon}
          title={title}
          description={description}
          index={index}
          key={`feature-web-${index}`}
          selectedItemIndex={selectedItemIndex}
          onClickCallback={() => clickCallback(index)}
        />
      ))}
    </Stack>
  );
};
