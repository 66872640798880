import { useState } from "react";
import { Dialog, DialogContent } from "@mui/material";
import { useTheme } from "@mui/system";
import { Box, Card, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { TimelineEventDTO } from "../../model/TimelineDTO";
import { MultilineTextField } from "../input/MultilineTextField";
import { ImageGrid } from "../img/ImageGrid";
import { ImageModal } from "../img/ImageModal";
import { EditButton } from "../buttons/EditButton";
import { Dates } from "../../utils/Dates";
import { CloseButton } from "../buttons/CloseButton";

export const TimelineModal = (props: any) => {
  const isOpen: boolean = props.isOpen;
  const closeCallback: any = props.closeCallback;
  const submitCallback: any = props.submitCallback;

  const imageURLs: string[] = props.imageURLs;
  const imageS3URLs: string[] = props.imageS3URLs;
  const thumbURLs: string[] = props.thumbURLs;

  const theme = useTheme();

  const [openImageGrid, setOpenImageGrid] = useState(false);
  const [url, setURL] = useState("");
  const [s3URL, setS3URL] = useState("");
  const [description, setDescription] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");

  const handleClose: any = () => {
    if (
      url.length > 0 &&
      description.length > 0 &&
      from.length > 0 &&
      to.length > 0
    ) {
      const evnt: TimelineEventDTO = {
        from: from,
        to: to,
        description: description,
        mediaURL: url,
        s3MediaURL: s3URL,
      };
      submitCallback(evnt);

      setURL("");
      setFrom("");
      setTo("");
      setDescription("");

      closeCallback();
    }
  };

  return (
    <>
      <Dialog open={isOpen} onClose={closeCallback} maxWidth="md">
        <CloseButton closeCallback={closeCallback} />
        <DialogContent>
          <Box>
            <EditButton clickCallback={() => setOpenImageGrid(true)} />
            <Card
              sx={{
                mb: 1,
                height: "300px",
                width: "100%",
                border: `1px solid hsla(220, 25%, 25%, .3)`,
                backgroundImage: `url(${url})`,
                backgroundSize: "cover",
                backgroundPosition: "top",
              }}
            />
            <MultilineTextField
              text={description}
              callback={(text: string) => setDescription(text)}
              placeholder="Enter description"
              sx={{
                mb: 1,
                opacity: 0.6,
                "& .MuiInputBase-input": {
                  textAlign: "left",
                },
              }}
            />
            <Box display="flex" flexDirection="row" sx={{ mb: 1 }}>
              <MultilineTextField
                text={from}
                callback={(text: string) => {
                  if (Dates.isValidDate(text)) {
                    setFrom(text);
                  }
                }}
                placeholder="Enter start date"
                sx={{
                  mr: 1,
                  opacity: 0.6,
                  "& .MuiInputBase-input": {
                    textAlign: "left",
                  },
                }}
              />
              <MultilineTextField
                text={to}
                callback={(text: string) => {
                  if (Dates.isValidDate(text)) {
                    setTo(text);
                  }
                }}
                placeholder="Enter end date"
                sx={{
                  opacity: 0.6,
                  "& .MuiInputBase-input": {
                    textAlign: "left",
                  },
                }}
              />
            </Box>
            <Button
              onClick={handleClose}
              size="large"
              sx={{
                color: "inherit",
                p: 0,
                height: "60px",
                width: "100%",
                display: "flex",
                border: "1px solid",
                borderColor: "hsla(220, 25%, 25%, .8)",
                backgroundColor: "rgb(33, 150, 243)",
                boxShadow: "none",
                justifyContent: "center",
              }}
            >
              <AddIcon />
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <ImageModal
        isOpen={openImageGrid}
        closeCallback={() => setOpenImageGrid(false)}
      >
        <ImageGrid
          imageURLs={imageURLs}
          imageS3URLs={imageS3URLs}
          thumbURLs={thumbURLs}
          clickCallback={(url: string, s3URL: string) => {
            setOpenImageGrid(false);
            setURL(url);
            setS3URL(s3URL);
          }}
        />
      </ImageModal>
    </>
  );
};
