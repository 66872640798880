import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

import { observer } from "mobx-react";
import {
  FeatureCardStyles,
  FeatureCardPlusStyles,
  FeatureCardBoxStyles,
} from "../styles/FeatureCardStyles";

import { scrollToSection } from "../scroll/ScrollActions";

export const FeatureCard = observer((props: any) => {
  const index = props.index;
  const selectedItemIndex = props.selectedItemIndex;
  const icon = props.icon;
  const description = props.description;
  const title = props.title;
  const onClickCallback = props.onClickCallback;

  return (
    <>
      <Card
        key={index}
        component={Button}
        onClick={onClickCallback}
        sx={[
          FeatureCardStyles,
          selectedItemIndex === index && FeatureCardPlusStyles,
        ]}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            textAlign: "left",
            flexDirection: { xs: "column", md: "row" },
            alignItems: { md: "center" },
            gap: 2.5,
          }}
        >
          <Box
            sx={[
              FeatureCardBoxStyles,
              selectedItemIndex === index && {
                color: "primary.main",
              },
            ]}
          >
            {icon}
          </Box>
          <div>
            <Typography
              gutterBottom
              sx={{ color: "text.primary", fontWeight: "medium" }}
            >
              {title}
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "text.secondary", mb: 1.5 }}
            >
              {description}
            </Typography>
            <Link
              color="primary"
              variant="body2"
              onClick={(event) => {
                event.stopPropagation();
              }}
              sx={{
                fontWeight: "bold",
                display: "inline-flex",
                alignItems: "center",
                "& > svg": { transition: "0.2s" },
                "&:hover > svg": { transform: "translateX(2px)" },
              }}
            >
              <Button onClick={() => scrollToSection("contact us")}>
                Learn more
              </Button>
              <ChevronRightRoundedIcon
                fontSize="small"
                sx={{ mt: "1px", ml: "2px" }}
              />
            </Link>
          </div>
        </Box>
      </Card>
    </>
  );
});
