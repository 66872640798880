import { useEffect } from "react";
import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";

import { MapData } from "./MapData";

/*
    //   // Find and modify the highway layers
    //   map.getStyle().layers.forEach((layer: any) => {
    //     if (layer.type === "line" && layer["source-layer"] === "road") {
    //       if (layer.id.includes("motorway") || layer.id.includes("trunk")) {
    //         // Emphasize major highways and freeways (motorway and trunk types)
    //         map.setPaintProperty(layer.id, "line-color", "#FF8C00"); // Change color to orange
    //         map.setPaintProperty(layer.id, "line-width", [
    //           "interpolate",
    //           ["linear"],
    //           ["zoom"],
    //           5,
    //           2, // At zoom level 5, line width is 2
    //           12,
    //           6, // At zoom level 12, line width is 6
    //           15,
    //           10, // At zoom level 15, line width is 10
    //         ]);
    //         map.setPaintProperty(layer.id, "line-opacity", 0.9); // Make it slightly more opaque
    //       }
    //     }
    //   });

    //   // Add labels to highways if the style includes a road label layer
    //   map.addLayer({
    //     id: "highway-labels",
    //     type: "symbol",
    //     source: "composite",
    //     "source-layer": "road_label", // Adjust to match your tile source's label layer
    //     filter: ["==", "class", "motorway"],
    //     layout: {
    //       "text-field": ["get", "name"],
    //       "text-size": 14,
    //       "symbol-placement": "line",
    //     },
    //     paint: {
    //       "text-color": "#FF8C00",
    //       "text-halo-color": "#ffffff",
    //       "text-halo-width": 2,
    //     },
    //   });
*/

function addDataLayer(
  map: any,
  geojsonData: any,
  sourceName: string,
  sourceColor: string
) {
  map.addSource(sourceName, {
    type: "geojson",
    data: geojsonData,
  });

  map.addLayer({
    id: `${sourceName}-layer`,
    type: "circle",
    source: sourceName,
    paint: {
      "circle-radius": 6,
      "circle-color": sourceColor,
    },
  });

  map.on("click", `${sourceName}-layer`, (e: any) => {
    const coordinates = e.features[0].geometry.coordinates.slice();
    const { title, description } = e.features[0].properties;

    new maplibregl.Popup()
      .setLngLat(coordinates)
      .setHTML(
        `<strong style="color: black;">${title}</strong><p style="color: black;">${description}</p>`
      )
      .addTo(map);
  });

  map.on("mouseenter", `${sourceName}-layer`, () => {
    map.getCanvas().style.cursor = "pointer";
  });

  map.on("mouseleave", `${sourceName}-layer`, () => {
    map.getCanvas().style.cursor = "";
  });
}

function initializeMap(mapData: MapData[]) {
  let localMap: any = new maplibregl.Map({
    container: "map",
    //style: "https://demotiles.maplibre.org/style.json",
    //style: "https://basemaps.cartocdn.com/gl/positron-gl-style/style.json",
    style: "https://basemaps.cartocdn.com/gl/voyager-gl-style/style.json",
    //style: "https://openmaptiles.github.io/dark-matter-gl-style/style.json",
    center: [-118.2426, 34.0549],
    zoom: 4,
  });

  localMap.on("load", () => {
    mapData.forEach((data) => {
      addDataLayer(localMap, data.geojsonFeatures, data.title, data.color);
    });
  });

  return localMap;
}

export const Map = (props: any) => {
  const width: string | number = props.width;
  const height: string | number = props.height;
  const mapData: MapData[] = props.mapData;
  let map: any = null;

  useEffect(() => {
    if (!map && mapData) {
      map = initializeMap(mapData);
    }
  }, [mapData]);

  // const loginDotGov = "0e68ff41997075bb68f0e1496a40879ac8e26f51";

  return <div id="map" style={{ width: width, height: height }} />;
};
