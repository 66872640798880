import { useState } from "react";
import { observer } from "mobx-react";
import { Grid, CardMedia } from "@mui/material";
import { ImageModal } from "./ImageModal";
import { TrashButton } from "../buttons/TrashButton";

export const ImageGridItem = observer((props: any) => {
  const imageURL: string = props.imageURL;
  const thumbURL: string = props.thumbURL;
  const deleteCallback: any = props.deleteCallback;
  const clickCallback: any = props.clickCallback;
  const openImages: boolean = props.openImages;
  const sx: any = props.sx;
  const imageSx: any = props.imageSx
    ? props.imageSx
    : {
        color: "inherit",
        p: 0,
        height: "300px",
        border: "1px solid",
        borderColor: "hsla(220, 25%, 25%, .3)",
        boxShadow: "none",
        cursor: "pointer",
      };

  const [open, setOpen] = useState(false);

  return (
    <Grid item xs={12} sm={6} md={4} sx={sx}>
      {deleteCallback && <TrashButton deleteCallback={deleteCallback} />}
      <CardMedia
        component="img"
        src={thumbURL}
        alt="Thumbnail"
        sx={imageSx}
        onClick={() => (clickCallback ? clickCallback() : setOpen(true))}
      />
      {openImages && (
        <ImageModal isOpen={open} closeCallback={() => setOpen(false)}>
          <CardMedia
            component="img"
            src={imageURL}
            alt="Full Image"
            style={{ width: "100%", height: "auto" }}
          />
        </ImageModal>
      )}
    </Grid>
  );
});
