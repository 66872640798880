import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

export const SandwichMenuButton = (props: any) => {
  const clickCallback: any = props.clickCallback;

  return (
    <IconButton aria-label="Menu button" onClick={clickCallback}>
      <MenuIcon />
    </IconButton>
  );
};
