import { useMemo, useState } from "react";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";

function getEventName(
  name: string | undefined,
  defaultName: string = "New Event"
) {
  return name && name.length > 0 ? name : defaultName;
}

function FormControlHelper(props: any) {
  let input: string = props.inputText;
  const callback: (newText: string) => any = props.callback;

  const [inputText, setInputText] = useState<string>(getEventName(input));
  const { focused } = useFormControl() || {};

  const helperText = useMemo(() => {
    if (focused) {
      return "Editing album name...";
    }
    return "";
  }, [focused]);

  if (!focused && input !== inputText) {
    setInputText(input);
    callback(getEventName(input));
  }

  return <FormHelperText>{helperText}</FormHelperText>;
}

export const OutlinedTextInput = (props: any) => {
  const [inputText, setInputText] = useState<string>(getEventName(props.text));
  const callback: (newText: string) => any = props.callback;
  const width: string =
    1.5 * (props.width ? props.width : props.text.length) + "ch";

  return (
    <form noValidate autoComplete="off">
      <FormControl sx={{ width: width, textAlign: "center" }}>
        <OutlinedInput
          onChange={(event: any) => {
            setInputText(event.target.value);
          }}
          value={inputText}
          placeholder="Please enter text"
          sx={{
            "& .MuiInputBase-input": {
              textAlign: "center",
            },
          }}
        />
        <FormControlHelper inputText={inputText} callback={callback} />
      </FormControl>
    </form>
  );
};
