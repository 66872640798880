import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import { observer } from "mobx-react";
import { menuModel } from "../../model/MenuModel";
import { CloseButton } from "../buttons/CloseButton";
import { SandwichMenuButton } from "../buttons/SandwichMenuButton";

export const AppBarMobileMenu = observer((props: any) => {
  const children: any = props.children;

  const { isDrawerOpen, setIsDrawerOpen } = menuModel;

  return (
    <>
      <SandwichMenuButton clickCallback={() => setIsDrawerOpen(true)} />
      <Drawer
        anchor="top"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      >
        <Box sx={{ p: 2, backgroundColor: "background.default" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {/*<ToggleColorMode
              mode={mode}
              toggleColorMode={toggleColorMode}
            />*/}
            <CloseButton closeCallback={() => setIsDrawerOpen(false)} />
          </Box>
          <Divider sx={{ my: 3 }} />
          {children}
        </Box>
      </Drawer>
    </>
  );
});
