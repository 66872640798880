import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

import { scrollToSection } from "../scroll/ScrollActions";

export const FeatureMobileCard = (props: any) => {
  const description = props.description;
  const title = props.title;
  const component: any = props.component;

  return (
    <>
      <Card
        variant="outlined"
        sx={{ display: { xs: "auto", sm: "none" }, mt: 4 }}
      >
        <Box sx={{ px: 2, py: 2 }}>
          <Typography
            gutterBottom
            sx={{ color: "text.primary", fontWeight: "medium" }}
          >
            {title}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary", mb: 1.5 }}>
            {description}
          </Typography>
          <Button
            onClick={() => scrollToSection("contact us")}
            color="primary"
            sx={{
              fontWeight: "bold",
              display: "inline-flex",
              alignItems: "center",
              "& > svg": { transition: "0.2s" },
              "&:hover > svg": { transform: "translateX(2px)" },
            }}
          >
            <span>Learn more</span>
            <ChevronRightRoundedIcon
              fontSize="small"
              sx={{ mt: "1px", ml: "2px" }}
            />
          </Button>
        </Box>
        {component}
      </Card>
    </>
  );
};
