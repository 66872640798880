
export class GeoJSON {
    static createGeoJSONFeature(
        lng: number,
        lat: number,
        stationName: string,
        city: string
    ) {
        const feature = {
            type: "Feature",
            geometry: {
                type: "Point",
                coordinates: [lng, lat],
            },
            properties: {
                title: stationName,
                description: city,
            },
        };
        return feature;
    }
      
    static createGeoJSONData(response: any) {
        const geojsonData = {
            type: "FeatureCollection",
            features: [
                ...response.map((item: any) =>
                    GeoJSON.createGeoJSONFeature(
                        item.geometry.coordinates[0],
                        item.geometry.coordinates[1],
                        item.properties.station_name,
                        item.properties.city
                    )
                ),
            ],
        };
        return geojsonData;
    }
}