import { useState } from "react";
import { observer } from "mobx-react";
import { VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import Card from "@mui/material/Card";
import { Box } from "@mui/material";

import { TimelineEventDTO } from "../../model/TimelineDTO";
import { MultilineTextField } from "../input/MultilineTextField";
import { gray } from "../themes/getLPTheme";
import { ImageGrid } from "../img/ImageGrid";
import { ImageModal } from "../img/ImageModal";
import { TrashButton } from "../buttons/TrashButton";
import { EditButton } from "../buttons/EditButton";

export const TimelineEvent = observer((props: any) => {
  const setDescriptionCallback: any = props.setDescriptionCallback;
  const evnt: TimelineEventDTO = props.evnt;
  const index: number = props.index;
  const imageURLs: string[] = props.imageURLs;
  const imageS3URLs: string[] = props.imageS3URLs;
  const thumbURLs: string[] = props.thumbURLs;
  const timelineEventMediaURLCallback: any =
    props.timelineEventMediaURLCallback;
  const deleteTimelineEventCallback: any = props.deleteTimelineEventCallback;
  const showDates: boolean =
    props.showDates !== undefined ? props.showDates : true;

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const borderColor: string = "hsla(220, 25%, 25%, .3)";

  return (
    <VerticalTimelineElement
      contentStyle={{
        boxShadow: "none",
        background: gray[800],
        border: `1px solid ${borderColor}`,
        borderRadius: "20px",
      }}
      contentArrowStyle={{
        borderRight: `10px solid ${borderColor}`,
      }}
      date={showDates ? `${evnt.from} - ${evnt.to}` : ""}
      iconStyle={{
        background: "rgb(33, 150, 243)",
        color: "#fff",
      }}
      icon={<PlayCircleIcon />}
      key={`timeline-${index}`}
    >
      <Box>
        {evnt.mediaURL && (
          <>
            <EditButton
              clickCallback={() => setOpen(true)}
              sx={{ left: "20px" }}
            />
            <TrashButton
              deleteCallback={() => deleteTimelineEventCallback()}
              sx={{ left: "50px" }}
            />
            <Card
              sx={{
                mb: 1,
                height: "300px",
                width: "100%",
                border: `1px solid ${borderColor}`,
                backgroundImage: `url(${evnt.mediaURL})`,
                backgroundSize: "cover",
                backgroundPosition: "top",
              }}
            />
          </>
        )}
        <MultilineTextField
          text={evnt.description}
          callback={(text: string) => setDescriptionCallback(text, index)}
          sx={{
            opacity: 0.6,
            "& .MuiInputBase-input": {
              textAlign: "left",
            },
          }}
        />
      </Box>
      <ImageModal isOpen={open} closeCallback={handleClose}>
        <ImageGrid
          imageURLs={imageURLs}
          imageS3URLs={imageS3URLs}
          thumbURLs={thumbURLs}
          clickCallback={(url: string, s3URL: string) => {
            timelineEventMediaURLCallback(url, s3URL);
            setOpen(false);
          }}
        />
      </ImageModal>
    </VerticalTimelineElement>
  );
});
