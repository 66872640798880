import { useAuth0 } from "@auth0/auth0-react";
import Button from "@mui/material/Button";
import { AuthPaths } from "./AuthPaths";

export const Auth0LogoutButton = (props: any) => {
  const { logout } = useAuth0();

  const appName: string = props.appName;

  return (
    <Button
      variant="text"
      color="primary"
      size="small"
      onClick={() =>
        logout({
          logoutParams: { returnTo: AuthPaths.logoutURL(appName) },
        })
      }
    >
      Sign Out
    </Button>
  );
};
