import { makeAutoObservable } from 'mobx';

class MenuModel {
  isDrawerOpen: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setIsDrawerOpen = (isOpen: boolean): void => {
    this.isDrawerOpen = isOpen;
  };
}

export const menuModel = new MenuModel();
