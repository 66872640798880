import { useTheme } from "@mui/system";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import { AccordionList, AccordionItem } from "../../accordions/AccordionList";

export const FAQAccordion = (props: any) => {
  const items: AccordionItem[] = props.items;

  const theme: any = useTheme();

  return (
    <Box id="faq">
      <Container
        id="contact us"
        sx={{
          pt: { xs: 4, sm: 12 },
          pb: { xs: 8, sm: 16 },
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Typography
          component="h2"
          variant="h2"
          sx={{
            color: "text.primary",
            width: { sm: "100%", md: "60%" },
            textAlign: { sm: "left", md: "center" },
            fontFamily: theme.typography.fontFamily,
          }}
        >
          Contact us
        </Typography>
        <Box sx={{ width: "100%" }}>
          <AccordionList items={items} />
        </Box>
      </Container>
    </Box>
  );
};
